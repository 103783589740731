@tailwind base;
@tailwind components;
@tailwind utilities;

.league-table {
  @apply border-spacing-4 border border-slate-500 mx-auto bg-slate-300
}

.table-caption {
  @apply text-2xl uppercase bg-gray-600 h-9 border-b-4 font-bold
}

.league-table-body {
  @apply text-sm font-bold
}

.league-table-name {
  @apply w-40 text-sm bg-sky-600 p-1
}

.league-table-header {
  @apply w-20 text-sm bg-sky-600 p-1 border-r-2
}

.cell-border-right {
  @apply border-r-2
}

.match-played {
  @apply h-8 bg-green-300 border-b-2
}

.match-played-2 {
  @apply h-8 bg-green-600 border-b-2
}

.match-table {
  @apply border-spacing-4 border border-slate-500 mx-auto bg-slate-300
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
